.form {
  margin-bottom: 1rem;
  margin-top: 0rem;
  flex: 1;
  text-align: center;
  align-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.form input,
.form label {
  display: block;
  color: white;
  font-size: 30px;
  height: 50%;
}

.form .extension select {
  display: block;
  color: black;
  text-align: center;
  border: 1px solid #ccc;
  background-color: white;
  border-style: solid;
  border-radius: 4px;
  font-size: 14px;
  height: 2.25rem;
}

.form .extension:hover select {
  border-color: #240370;
}

h3 {
  display: block;
  color: white;
  font-size: 30px;
  text-align: center;
  padding-bottom: -1rem;
}

.form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form input {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 63%;
  color: grey;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.form input:focus {
  outline: none;
  border-color: #240370;
  color: black;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.error {
  color: #b40e0e;
}

.saveas {
  margin-bottom: 3rem;
  height: 2rem;
}

.saveas h2 {
  text-align: left;
  font-weight: bold;
  font-size: 50px;
  margin-top: 5px;
  margin-bottom: 1rem;
}

/* .form .extension section {
  height: 3rem;
} */
