.options {
  /*display: flex;
  flex-direction: column;
  align-self: left;*/
  overflow: auto;
}

.options h2 {
  text-align: left;
  font-weight: bold;
  font-size: 50px;
  margin-top: 5px;
  margin-bottom: 1rem;
}

.cajitas {
  display: block;
  margin: auto;
  width: 60%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

hr {
  width: 75%;
  color: white;
}

.separation input {
  align-content: center;
}

/*
.separation hr {
  color: black;
  height: 10;
  width: 10;
}
*/
