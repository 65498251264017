.form {
  margin: 1rem 0;
  height: 50%;
  overflow: auto;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.overall {
  overflow: auto;
}

.checkboxes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.checkboxes label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  display: block;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.buttons button:hover {
  box-shadow: none;
  margin-left: 0.5rem;
  color: black;
}
