html {
  height: 100%;
}

:root {
  --button-color: white;
  --button-text: white;
  --button-color-hover: lightgray;
  --button-text-hover: black;
  --button-disabled: red;
  --button-disabled-hover: #740808;
  --button-valid: #32cd32;
  --button-valid-hover: #228b22;
  --button-text-light: white;
}

.form {
  margin-bottom: 1rem;
  flex: 1;
  text-align: center;
  align-self: center;
  box-sizing: border-box;
}

main {
  position: absolute;
  top: 50%;
  left: 50%;
  width: min(80%, 35rem);
  padding: 30px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.form div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

input {
  position: relative;
}

.form input,
.form label,
form legend {
  display: block;
  color: white;
  font-size: 30px;
  text-align: center;
}

form legend {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 0rem;
}

.form label {
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.form input,
.form select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: min(25rem, 95%);
  color: #141e30;
  margin: auto;
}

.form input:focus {
  outline: none;
  border-color: #240370;
  color: black;
}

button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: var(--button-color);
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 2px;
  background: inherit;
  border: none;
}

button:hover {
  background: var(--button-color-hover);
  color: var(--button-text-hover);
  box-shadow: 0 0 5px var(--button-color), 0 0 25px var(--button-color), 0 0 50px var(--button-color),
    0 0 100px var(--button-color);
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  color: var(--button-disabled);
}

button:disabled:hover {
  background: var(--button-disabled-hover);
  box-shadow: 0 0 5px var(--button-disabled-hover), 0 0 25px var(--button-disabled-hover), 0 0 50px var(--button-disabled-hover),
    0 0 100px var(--button-disabled-hover);
}

.mainButton {
  color: var(--button-valid);
}

.mainButton:hover {
  background: var(--button-valid-hover);
  box-shadow: 0 0 5px var(--button-valid-hover), 0 0 25px var(--button-valid-hover), 0 0 50px var(--button-valid-hover),
    0 0 100px var(--button-valid-hover);
  color: var(--button-text-light);
}

button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--button-color));
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

button span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, var(--button-color));
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.3s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

button span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, var(--button-color));
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.55s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

button span {
  position: absolute;
  display: block;
}

button span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(0deg, transparent, var(--button-color));
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.8s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

button:disabled span:nth-child(1) {
  background: linear-gradient(90deg, transparent, red);
}

button:disabled span:nth-child(2) {
  background: linear-gradient(180deg, transparent, red);
}

button:disabled span:nth-child(3) {
  background: linear-gradient(270deg, transparent, red);
}

button:disabled span:nth-child(4) {
  background: linear-gradient(0deg, transparent, red);
}

.mainButton span:nth-child(1) {
  background: linear-gradient(90deg, transparent, var(--button-valid));
}

.mainButton span:nth-child(2) {
  background: linear-gradient(180deg, transparent, var(--button-valid));
}

.mainButton span:nth-child(3) {
  background: linear-gradient(270deg, transparent, var(--button-valid));
}

.mainButton span:nth-child(4) {
  background: linear-gradient(0deg, transparent, var(--button-valid));
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.actions button {
  margin-left: 1rem;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: inherit;
  background-color: var(--button-color);
}

.error {
  color: #b40e0e;
}
