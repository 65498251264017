.form {
  margin: 1rem 0;
  height: 50%;
  overflow: auto;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.overall {
  overflow: auto;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.buttons button:hover {
  box-shadow: none;
  margin-left: 0.5rem;
  color: black;
}

.links {
  margin-top: 3rem;
}

.links li {
  filter: invert(100%);
  margin: 0.5rem;
  display: inline;
}

.links li > a {
  margin-left: 0.5rem;
  color: black;
}
